//pegar o tamanho do header
var tamanhoHeader = $('header').innerHeight();

$(window).scroll(function(){
	var top    	= $(window).scrollTop(),
      $menu   = $('header');
	if(top > 115){
    $menu.addClass('stick').find('nav').stop().animate({
			'top': '83px'
		});
	}else{
    $menu.removeClass('stick').find('nav').stop().animate({
			'top': tamanhoHeader
		});
	}

});


$(document).ready(function() {

	function controleAltura(){
		$('.view-index').find('.banner').css('height', window.innerHeight);
		$('.view-index').find('.loadBefore').css('height', window.innerHeight);
	}

	$(window).resize(function() {
		controleAltura();
	});

	$(window).trigger('resize');


  $('header').on('click', 'button', function(e){
      e.preventDefault();
      $(this).toggleClass('active');
      $(this).parent().find('nav.menu').addClass('menuResponsivo').slideToggle();
			$(this).find('nav').stop().animate({
				'top': tamanhoHeader
			});
  });


//Adicionar class active ao menu
  var url = window.location.href;
  $('header nav').on('click', 'a', function(){
    $(this).parent().find('a').removeClass('active');
    $(this).addClass('active');
  });

  // Will only work if string in href matches with location
  $('header nav a[href="'+ url +'"]').addClass('active');

  // Will also work for relative and absolute hrefs
  $('header nav a').filter(function() {
      return this.href == url;
  }).addClass('active');

  var submenuLink = $('header nav>ul>li.sub ul.submenu li').find('a');
  if( submenuLink.hasClass('active') ) {
    $('header nav>ul>li ul.submenu').css({'display':'block'});
  }

//Carrossel das internas
  $(".interna .carrossel .fotos").owlCarousel({
    autoPlay: 3000,
    items: 1
  });

//Carrossel das internas
  $(".view-index .exposicao-destaques .slider").owlCarousel({
    autoPlay: 3000,
    items: 1,
    smartSpeed:450,
    dotData:true
  });

//lightbpx do formulário das atividades
  $('.pop').magnificPopup({
    type: 'inline',
    preloader: false,
    focus: '#name',

    // When elemened is focused, some mobile browsers in some cases zoom in
    // It looks not nice, so we disable it:
    callbacks: {
      beforeOpen: function() {
        if($(window).width() < 700) {
          this.st.focus = false;
        } else {
          this.st.focus = '#name';
        }
      }
    }
  });

  //fechar lightbox das atividades
  $('.formPopup').on('click', '.fechar', function(){
      $(this).magnificPopup('close');
  });

//Lightbox da galeria de sobre
  $('.popup-gallery').magnificPopup({
		delegate: 'a',
		type: 'image',
		tLoading: 'Carregando #%curr%...',
		mainClass: 'mfp-img-mobile',
    tClose: 'Fechar (Esc)', // Alt text on close button
		gallery: {
			enabled: true,
			navigateByImgClick: true,
			preload: [0,1], // Will preload 0 - before current, and 1 after the current image
      tPrev: 'Anterior', // Alt text on left arrow
      tNext: 'Próxima', // Alt text on right arrow
      tCounter: '%curr% de %total%' // Markup for "1 of 7" counter
		},
		image: {
			tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
			// titleSrc: function(item) {
			// 	return item.el.attr('title') + '<small>by Marsel Van Oosten</small>';
			// }
		}
	});

/* Animações da home exposição destaque */
  var homeSlider = $('.view-index .exposicao-destaques .slider article');
  homeSlider.find('.texto').css('opacity','0').addClass('continuous-true animated');
  homeSlider.find('.imagemUm').css('opacity','0').addClass('continuous-true animated');
  homeSlider.find('.imagemDois .image').css('opacity','0').addClass('continuous-true animated');
  homeSlider.find('.imagemDois .link').css('opacity','0').addClass('continuous-true animated');
  $('.view-index .exposicao-destaques .slider .owl-dots').css('opacity','0').addClass('animated');

  homeSlider.each(function() {
    $(this).waypoint(function(direction) {
      if (direction === 'down') {
        $(this.element).find('.texto').removeClass('fadeOutLeft').addClass('fadeInLeft');
        $(this.element).find('.imagemUm').removeClass('fadeOutRight').addClass('fadeInRight');
        $(this.element).find('.imagemDois .image').removeClass('fadeOutUp').addClass('fadeInUp');
        $(this.element).find('.imagemDois .link').removeClass('fadeOutRight').addClass('fadeInRight');
        $('.view-index .exposicao-destaques .slider .owl-dots').removeClass('fadeOutUp').addClass('fadeInUp');
      } else {
        $(this.element).find('.texto').removeClass('fadeInLeft').addClass('fadeOutLeft');
        $(this.element).find('.imagemUm').removeClass('fadeInRight').addClass('fadeOutRight');
        $(this.element).find('.imagemDois .image').removeClass('fadeInUp').addClass('fadeOutUp');
        $(this.element).find('.imagemDois .link').removeClass('fadeInRight').addClass('fadeOutRight');
        $('.view-index .exposicao-destaques .slider .owl-dots').removeClass('fadeInUp').addClass('fadeOutUp');
      }
    }, {
      offset: '85%',
      continuous: true
    });
  });

/* Animações da home acontece */
  var homeAcontece = $('.view-index .acontece .conteudo');
  homeAcontece.css('opacity','0').addClass('continuous-true animated');
  $('.view-index .acontece').waypoint(function(direction) {
    if (direction === 'down') {
      homeAcontece.removeClass('fadeOut').addClass('fadeInUp');
    }
    else {
      homeAcontece.removeClass('fadeInUp').addClass('fadeOut');
    }
  }, {
    offset: '70%',
    continuous: true
  });

/* Animações da home newsletter */
  var homeNewsletter = $('.view-index .newsletter .conteudo');
  homeNewsletter.css('opacity','0').addClass('continuous-true animated');
  $('.view-index .newsletter').waypoint(function(direction) {
    if (direction === 'down') {
      homeNewsletter.removeClass('fadeOut').addClass('fadeIn');
    }
    else {
      homeNewsletter.removeClass('fadeIn').addClass('fadeOut');
    }
  }, {
    offset: '70%',
    continuous: true
  });

/* EXPOSICOES */
  var exposicoesArticle = $('.view-exposicoes article');
  exposicoesArticle.find('.texto, .imagem').css('opacity','0').addClass('continuous-true animated');
  exposicoesArticle.each(function() {
    $(this).waypoint(function() {
      $(this.element).find('.texto').addClass('animated fadeInLeft');
      $(this.element).find('.imagem').addClass('animated fadeInRight');
    },
    {
      offset: '90%'
    });
  });

/* COLECOES */
  var colecoesArticle = $('.view-colecoes article');
  colecoesArticle.find('.texto, .imagem').css('opacity','0').addClass('continuous-true animated');
  colecoesArticle.each(function() {
    $(this).waypoint(function() {
      $(this.element).find('.texto').addClass('animated fadeInLeft');
      $(this.element).find('.imagem').addClass('animated fadeInRight');
    },
    {
      offset: '90%'
    });
  });

/* ACONTECE */
  var aconteceArticle = $('.view-acontece article');
  aconteceArticle.css('opacity','0').addClass('continuous-true animated');
  aconteceArticle.each(function() {
    $(this).waypoint(function() {
      $(this.element).addClass('animated fadeInDown');
    },
    {
      offset: '80%'
    });
  });

/* OFICINAS */
  var oficinasArticle = $('.view-oficinas article');
  oficinasArticle.css('opacity','0').addClass('continuous-true animated');
  oficinasArticle.each(function() {
    $(this).waypoint(function() {
      $(this.element).addClass('animated fadeInDown');
    },
    {
      offset: '85%'
    });
  });

/* PALESTRAS */
  var palestrasArticle = $('.view-palestras article');
  palestrasArticle.css('opacity','0').addClass('continuous-true animated');
  palestrasArticle.each(function() {
    $(this).waypoint(function() {
      $(this.element).addClass('animated fadeInDown');
    },
    {
      offset: '85%'
    });
  });

  /* MUSICAS */
  var musicasArticle = $('.view-musicas article');
  musicasArticle.css('opacity','0').addClass('continuous-true animated');
  musicasArticle.each(function() {
    $(this).waypoint(function() {
      $(this.element).addClass('animated fadeInDown');
    },
    {
      offset: '85%'
    });
  });

});


$(window).load(function(){
	//puxar conteudo para baixo de acordo com o tamanho do header
	var padTop = function(el) {
		$(el).css({
			'padding-top': tamanhoHeader
		});
	};

	padTop('.view-exposicoes .interna');
	padTop('.view-colecoes .interna');

	$('.view-index .loadBefore').fadeOut();
});
